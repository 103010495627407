<template>
  <div class="pdfDisplaySection">
    <div class="content-container">
      <!-- Left Section with PDF columns -->
      <div class="left-section">
        <div class="left-top-bar">
          <div class="buttonSection left">
            <button class="backButton" @click="goBack()">
              ⟵ {{ $t("compare.backBtn") }}
            </button>
          </div>
          <h3 class="compare-heading">{{ $t("compare.heading") }}</h3>
          <div class="right"></div>
        </div>
        <div class="zoom-controls-container">
          <!-- Zoom controls for PDF1 -->
          <div class="zoom-controls">
            <label>{{ $t("compare.zoom") }} 1:</label>
            <select id="zoomSelect1" @change="onZoomChange(1, $event)">
              <option value="25">25%</option>
              <option value="50">50%</option>
              <option value="75">75%</option>
              <option value="100" selected>100%</option>
              <option value="125">125%</option>
              <option value="150">150%</option>
              <option value="190">190%</option>
            </select>
            <button @click="zoomOut(1)">-</button>
            <button @click="zoomIn(1)">+</button>
          </div>
          <!-- Zoom controls for PDF2 -->
          <div class="zoom-controls">
            <label>{{ $t("compare.zoom") }} 2:</label>
            <select id="zoomSelect2" @change="onZoomChange(2, $event)">
              <option value="25">25%</option>
              <option value="50">50%</option>
              <option value="75">75%</option>
              <option value="100" selected>100%</option>
              <option value="125">125%</option>
              <option value="150">150%</option>
              <option value="190">190%</option>
            </select>
            <button @click="zoomOut(2)">-</button>
            <button @click="zoomIn(2)">+</button>
          </div>
        </div>
        <div class="pdf-container">
          <!-- Scrollable container for PDF1 -->
          <div class="pdf-scroll-container">
            <div class="pdf-column" id="pdfColumn1" @mousedown="startDrag($event, 1)"></div>
          </div>
          <!-- Scrollable container for PDF2 -->
          <div class="pdf-scroll-container">
            <div class="pdf-column" id="pdfColumn2" @mousedown="startDrag($event, 2)"></div>
          </div>
        </div>
      </div>

      <!-- Right Section with Sidebar -->
      <div class="right-section">
        <h3 class="compare-heading">{{ $t("compare.sidebarHeading") }}</h3>
        <div class="sidebar">
          <!-- Page Content -->
          <div v-if="currentPageContent" class="page-card">
            <h4>
              {{ $t("compare.sidebarPage") }} {{ currentPageContent.pageNum }}
            </h4>

            <div class="middle">
              <button class="backButton" @click="addHighlightsAll">
                {{ $t("compare.highlight") }}
              </button>
              <button class="backButton" @click="removeHighlightsAll">
                {{ $t("compare.removeHighlight") }}
              </button>
            </div>
            <div class="change-section" v-if="currentPageContent.added.length">
              <h6 class="added">{{ $t("compare.added") }}</h6>
              <div class="change-items">
                <div v-for="(item, i) in currentPageContent.added" :key="i" :class="{
                  'change-item': true,
                  'change-item active': item.show,
                }" @click="
                    handleClick(
                      item,
                      'added',
                      currentPageContent.pageNum,
                      'new'
                    )
                    ">
                  <span>{{ item.word }}</span>
                </div>
              </div>
            </div>

            <div class="change-section" v-if="currentPageContent.removed.length">
              <h6 class="removed">{{ $t("compare.removed") }}</h6>
              <div class="change-items">
                <div v-for="(item, i) in currentPageContent.removed" :key="i"
                  :class="['change-item', { active: item.show }]" @click="
                    handleClick(
                      item,
                      'removed',
                      currentPageContent.pageNum,
                      'old'
                    )
                    ">
                  <span>{{ item.word }}</span>
                </div>
              </div>
            </div>

            <div class="change-section" v-if="currentPageContent.changed.length">
              <h6 class="changed">{{ $t("compare.changed") }}</h6>
              <div class="change-items">
                <div v-for="(item, i) in currentPageContent.changed" :key="i"
                  :class="['change-item', { active: item.show }]" @click="
                    handleClickChanged(
                      item,
                      'changed',
                      currentPageContent.pageNum
                    )
                    ">
                  <span>{{ item.word }} ► {{ item.newWord }}</span>
                </div>
              </div>
            </div>

            <!-- Images -->
            <div class="change-section" v-if="images">
              <h6 class="changed">{{ $t("compare.changed") }}</h6>
                <div v-for="(item, i) in currentPageContent.images" :key="i"
                  :class="['change-item', { active: item.show }]">
                  <div class="image-container" @click="handleClickImage(item, currentPageContent.pageNum)">
                    <img :src="item.image_diff1" alt="Image" class="image" />
                    <img :src="item.image_diff2" alt="Image" class="image" />
                  </div>
                </div>
            </div>
          </div>
          <!-- Pagination Controls -->
          <div class="pagination-controls">
            <button @click="previousPage" :disabled="currentPage === 1">
              {{ $t("compare.previous") }}
            </button>
            <input type="number" v-model="pageInput" @keyup.enter="goToPage" min="1" :max="totalPages" />
            <button @click="nextPage" :disabled="currentPage === totalPages">
              {{ $t("compare.next") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-show="loading" class="loading">
      <p>{{ $t("compare.loadingCmp") }}</p>
    </div>
  </div>
</template>

<script>
import * as pdfjsLib from "pdfjs-dist";
import axios from "axios";
import EnvProvider from "jvjr-docker-env";
import { h } from "vue";
import { fas } from "@fortawesome/free-solid-svg-icons";

export default {
  emits: ["goBack"],
  props: {
    pdf1: File,
    pdf2: File,
    force: false,
  },
  data() {
    return {
      loading: false,
      pdfA: null,
      pdfB: null,
      pdfDocumentA: null,
      pdfDocumentB: null,
      pagesDiff: [],
      currentPage: 1,
      pageInput: 1,
      sidebarContent: {},
      currentPageContent: null, // Holds the current page's difference data
      totalPages: 0,
      pageNum: 0,
      dragging: null, // To keep track of which PDF is being dragged
      startX: 0,
      startY: 0,
      initialLeft: 0,
      initialTop: 0,
      currentZoom: { 1: 100, 2: 100 }, // Store current zoom for each PDF
      zoomLevels: [25, 50, 75, 100, 125, 150, 190], // Fixed set of zoom levels
    };
  },
  async mounted() {
    this.loading = true;
    await this.compareFiles();
    this.loading = false;
    this.storeOriginalCanvasSizes(1); // For PDF1
    this.storeOriginalCanvasSizes(2); // For PDF2

    // on resize, update the position of the highlights
    window.addEventListener("resize", this.updateHighlightPositionsResize);
  },
  methods: {
    updateHighlightPositionsResize() {
      this.sidebarContent.forEach((item, index) => {
        item.added.forEach((added) => {
          if (added.show) {
            this.positionDiv(added.id, added.position, "added", item.pageNum, "new", !added.show);
          }
        });
        item.removed.forEach((removed) => {
          if (removed.show) {
            this.positionDiv(removed.id, removed.position, "removed", item.pageNum, "old", !removed.show);
          }
        });
        item.changed.forEach((changed) => {
          if (changed.show) {
            this.positionDiv(changed.id, changed.oldPosition, "changed", item.pageNum, "old", !changed.show);
            this.positionDiv(changed.id, changed.newPosition, "changed", item.pageNum, "new", !changed.show);
          }
        });
        item.images.forEach((image) => {
          if (image.show) {
            this.positionDiv(image.id, image.position, "changed", item.pageNum, "new", !image.show);
            this.positionDiv(image.id, image.position, "changed", item.pageNum, "old", !image.show);
          }
        });
      });
    },
    handleClick(item, type, pageNum, positionType) {
      if (item.show) {
        item.show = false;
        this.positionDiv(item.id, item.position, type, pageNum, positionType, true);
      } else {
        item.show = true;
        this.positionDiv(item.id, item.position, type, pageNum, positionType, false);
      }

    },
    handleClickChanged(item, type, pageNum) {
      if (item.show) {
        item.show = false;
        this.positionDiv(item.id, item.oldPosition, type, pageNum, "old", true);
        this.positionDiv(item.id, item.newPosition, type, pageNum, "new", true);
      } else {
        item.show = true;
        this.positionDiv(item.id, item.oldPosition, type, pageNum, "old", false);
        this.positionDiv(item.id, item.newPosition, type, pageNum, "new", false);
      }
    },
    handleClickImage(item, pageNum) {
      if (item.show) {
        item.show = false;
        this.positionDiv(item.id, item.position, "changed", pageNum, "new", true);
        this.positionDiv(item.id, item.position, "changed", pageNum, "old", true);
      } else {
        item.show = true;
        this.positionDiv(item.id, item.position, "changed", pageNum, "new", false);
        this.positionDiv(item.id, item.position, "changed", pageNum, "old", false);
      }
    },
    async removeHighlightsAll() {
      this.sidebarContent.forEach((item, index) => {
        item.added.forEach((added) => {
          added.show = false;
          this.positionDiv(added.id, added.position, "added", item.pageNum, "new", true);
        });
        item.changed.forEach((changed) => {
          changed.show = false;
          this.positionDiv(changed.id, changed.oldPosition, "changed", item.pageNum, "old", true);
          this.positionDiv(changed.id, changed.newPosition, "changed", item.pageNum, "new", true);
        });
        item.removed.forEach((removed) => {
          removed.show = false;
          this.positionDiv(removed.id, removed.position, "removed", item.pageNum, "old", true);
        });
        item.images.forEach((image) => {
          image.show = false;
          this.positionDiv(image.id, image.position, "changed", item.pageNum, "new", true);
          this.positionDiv(image.id, image.position, "changed", item.pageNum, "old", true);
        });
      });
    },

    async addHighlightsAll() {
      this.sidebarContent.forEach((item, index) => {
        item.added.forEach((added) => {
          added.show = true;
          this.positionDiv(added.id, added.position, "added", item.pageNum, "new", false);
        });
        item.changed.forEach((changed) => {
          changed.show = true;
          this.positionDiv(changed.id, changed.oldPosition, "changed", item.pageNum, "old", false);
          this.positionDiv(changed.id, changed.newPosition, "changed", item.pageNum, "new", false);
        });
        item.removed.forEach((removed) => {
          removed.show = true;
          this.positionDiv(removed.id, removed.position, "removed", item.pageNum, "old", false);
        });
        item.images.forEach((image) => {
          image.show = true;
          this.positionDiv(image.id, image.position, "changed", item.pageNum, "new", false);
          this.positionDiv(image.id, image.position, "changed", item.pageNum, "old", false);
        });
      });
    },

    async positionDiv(id, position, type, pageNum, positionType, hide) {
      let divId = positionType === "new" ? id + "-pdfColumn2" : id + "-pdfColumn1";
      const highlightDiv = document.getElementById(divId);

      let page = positionType === "new" ? await this.pdfDocumentB.getPage(pageNum) : await this.pdfDocumentA.getPage(pageNum);
      const pageWidth = page.view[2];
      const pageHeight = page.view[3];

      if (highlightDiv) {
        const [x0, y0, x1, y1] = position;

        const parentId =
          positionType !== "new"
            ? `pdf-page-pdfColumn1-` + pageNum
            : `pdf-page-pdfColumn2-` + pageNum;

        const parent = document.getElementById(parentId);
        const DivRect = parent.getBoundingClientRect();

        const scaleX = DivRect.width / pageWidth;
        const scaleY = DivRect.height / pageHeight;

        highlightDiv.style.position = "absolute";

        highlightDiv.style.left = `${x0 * scaleX}px`;
        highlightDiv.style.top = `${y0 * scaleY + DivRect.height * (pageNum - 1)
          }px`;

        // Set the size of the div
        highlightDiv.style.width = `${(x1 - x0) * scaleX}px`;
        highlightDiv.style.height = `${(y1 - y0) * scaleY}px`;

        if (type === "added") {
          highlightDiv.style.border = "2px solid rgba(0, 255, 0, 0.5)";
          highlightDiv.style.backgroundColor = "rgba(0, 255, 0, 0.3)";
        } else if (type === "changed") {
          highlightDiv.style.border = "2px solid rgba(255, 255, 0, 0.5)";
          highlightDiv.style.backgroundColor = "rgba(255, 255, 0, 0.3)";
        } else if (type === "removed") {
          highlightDiv.style.border = "2px solid rgba(255, 0, 0, 0.5)";
          highlightDiv.style.backgroundColor = "rgba(255, 0, 0, 0.3)";
        }
        if (hide) {
          highlightDiv.style.display = "none";
        } else {
          highlightDiv.style.display = "block";
        }
      }
    },
    storeOriginalCanvasSizes(pdfNumber) {
      const pdfColumn = document.getElementById(`pdfColumn${pdfNumber}`);
      if (pdfColumn) {
        const canvases = pdfColumn.getElementsByClassName("pdf-canvas");
        for (let canvas of canvases) {
          canvas.setAttribute("data-original-width", canvas.clientWidth);
          canvas.setAttribute("data-original-height", canvas.clientHeight);
        }
      }
    },
    goBack() {
      // Clear the PDF columns
      this.clearPdfColumns();

      // Reset PDF data variables
      this.pdfA = null;
      this.pdfB = null;

      // Emit the goBack event to navigate to the previous view
      this.$emit("goBack");
    },
    clearPdfColumns() {
      const pdfColumn1 = document.getElementById("pdfColumn1");
      const pdfColumn2 = document.getElementById("pdfColumn2");

      if (pdfColumn1) {
        pdfColumn1.innerHTML = "";
      }

      if (pdfColumn2) {
        pdfColumn2.innerHTML = "";
      }
    },
    async compareFiles() {
      if (this.pdf1 == null || this.pdf2 == null) {
        console.error("One or both files not selected");
        this.$store.commit("pushMsg", {
          type: "error",
          text: this.$t("upload.notSelected"),
        });
        return;
      }

      try {
        // Code to compare files and display comparison result
        let rawData1B64 = await this.convertToBase64(this.pdf1);
        let rawData2B64 = await this.convertToBase64(this.pdf2);

        let data = { 'pdf1': rawData1B64, 'pdf2': rawData2B64, 'force': this.force};
        const apiUrl = EnvProvider.value('API_URL') + this.$root.API_COMPARE;
        const response = await axios.post(apiUrl, data, {
          headers: { "Content-Type": "application/json" },
        });
        this.pdfA = response.data["pdf_A"];
        this.pdfB = response.data["pdf_B"];
        this.pagesDiff = response.data["pages_diff"]; // Store the pages_diff data
        console.log("Comparison result:", response.data);
        this.images = response.data["image_diff"];
        this.images.forEach((page, index) => {
          page.forEach((item, i) => {
            item.show = false;
          });
        });
        
        this.pageNum = 1; // Initialize with the first page
        this.updateSidebarContent();
        await this.loadPdfDocuments(this.pdfA,this.pdfB);
        await this.renderPdfDocument("pdfColumn1");
        await this.renderPdfDocument("pdfColumn2");
      } catch (error) {
        console.error('Error comparing PDFs:', error);

        // Default error message
        let errorMsg = "Unidentified error";

        if (error.response?.data?.error_code) {
          const errorMessages = {
            1: "Unidentified error during route execution",
            2: "Unidentified error during comparison",
            3: "The PDFs are too different to compare. Please try again with more similar files."
          };

          // Get the message based on error_code or fallback to default
          errorMsg = errorMessages[error.response.data.error_code] || errorMsg;
        }

        // Commit the error message to the store
        this.$store.commit('pushMsg', { type: 'error', text: errorMsg });

        this.goBack();
      }
    },
    async convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    async loadPdfDocuments(base64DataA, base64DataB){
      pdfjsLib.GlobalWorkerOptions.workerSrc =
        "/node_modules/pdfjs-dist/build/pdf.worker.js";

      const binaryStringA = atob(base64DataA.split(",")[1]);
      const binaryStringB = atob(base64DataB.split(",")[1]);

      const arrayBufferA = new ArrayBuffer(binaryStringA.length);
      const arrayBufferB = new ArrayBuffer(binaryStringB.length);

      const uint8ArrayA = new Uint8Array(arrayBufferA);
      const uint8ArrayB = new Uint8Array(arrayBufferB);

      for (let i = 0; i < binaryStringA.length; i++) {
        uint8ArrayA[i] = binaryStringA.charCodeAt(i);
      }
      for (let i = 0; i < binaryStringB.length; i++) {
        uint8ArrayB[i] = binaryStringB.charCodeAt(i);
      }

      this.pdfDocumentA = await pdfjsLib.getDocument({ data: uint8ArrayA }).promise;
      this.pdfDocumentB = await pdfjsLib.getDocument({ data: uint8ArrayB }).promise;
    },
    async renderPdfDocument(columnId) {
      try {
        let pdfDocument = columnId === 'pdfColumn1' ? this.pdfDocumentA : this.pdfDocumentB;
        const totalPages = pdfDocument.numPages;

        for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
          const pdfPage = await pdfDocument.getPage(pageNum);
          
          const viewport = pdfPage.getViewport(2.0);

          const canvas = document.createElement("canvas");
          canvas.className = "pdf-canvas";
          canvas.id = `pdf-canvas-${columnId}-${pageNum}`;
          canvas.width = viewport.width;
          canvas.height = viewport.height;
          const ctx = canvas.getContext("2d");

          const renderTask = pdfPage.render({
            canvasContext: ctx,
            viewport: viewport,
          });
          await renderTask.promise;

          const divOverlay = document.createElement("div");
          divOverlay.className = "pdf-page";
          divOverlay.id = `pdf-page-${columnId}-${pageNum}`;
          divOverlay.appendChild(canvas);

          // add Divs for highlights to the page
          const pageDivs = this.sidebarContent[pageNum - 1];

          pageDivs.added.forEach((added) => {
            //  create a div for each added word
            let highlight = document.createElement("div");
            highlight.className = "highlight-added";
            highlight.id = added.id + `-${columnId}`;
            divOverlay.appendChild(highlight);
          });

          pageDivs.removed.forEach((removed) => {
            //  create a div for each removed word
            let highlight = document.createElement("div");
            highlight.className = "highlight-removed";
            highlight.id = removed.id + `-${columnId}`;
            divOverlay.appendChild(highlight);
          });

          pageDivs.changed.forEach((changed) => {
            //  create a div for each changed word
            let highlight = document.createElement("div");
            highlight.className = "highlight-changed";
            highlight.id = changed.id + `-${columnId}`;
            divOverlay.appendChild(highlight);
          });

          pageDivs.images.forEach((image) => {
            //  create a div for each image
            let highlight = document.createElement("div");
            highlight.className = "highlight-image";
            highlight.id = image.id + `-${columnId}`;
            divOverlay.appendChild(highlight);
          });

          document.getElementById(columnId).appendChild(divOverlay);

          let divRect = divOverlay.getBoundingClientRect();

          this.widthDiv = divRect.width;
          this.heightDiv = divRect.height;
        }
      } catch (error) {
        console.error("Error rendering PDF:", error);
      }
    },
    onZoomChange(pdfNumber, event) {
      const zoomValue = parseInt(event.target.value, 10);
      this.setZoom(pdfNumber, zoomValue);
    },
    zoomIn(pdfNumber) {
      this.adjustZoom(pdfNumber, 1); // Move to the next zoom level
    },
    zoomOut(pdfNumber) {
      this.adjustZoom(pdfNumber, -1); // Move to the previous zoom level
    },
    setZoom(pdfNumber, zoomValue) {
      const pdfColumn = document.getElementById(`pdfColumn${pdfNumber}`);
      if (pdfColumn) {
        const scale = zoomValue / 100;

        // Apply the scale transformation to the pdf-column If if the transform the div, the highlights will not be positioned correctly
        // pdfColumn.style.transform = `scale(${scale})`;
        // pdfColumn.style.transformOrigin = "top left";

        // Adjust the width and height of the pdf-column to accommodate zoomed content
        pdfColumn.style.width = `${100 * scale}%`;
        pdfColumn.style.height = `${100 * scale}%`;

        // Update the dropdown value
        const dropdown = document.getElementById(`zoomSelect${pdfNumber}`);
        if (dropdown) {
          dropdown.value = zoomValue;
        }
      }
      this.updateHighlightPositionsResize();
    },
    adjustZoom(pdfNumber, direction) {
      const currentZoom = this.currentZoom[pdfNumber];
      const currentIndex = this.zoomLevels.indexOf(currentZoom);

      let newIndex = currentIndex + direction;
      if (newIndex < 0) newIndex = 0;
      if (newIndex >= this.zoomLevels.length)
        newIndex = this.zoomLevels.length - 1;

      const newZoom = this.zoomLevels[newIndex];
      this.currentZoom[pdfNumber] = newZoom;
      this.setZoom(pdfNumber, newZoom);
    },
    startDrag(event, pdfNumber) {
      // Prevent default behavior to avoid issues with text selection
      event.preventDefault();

      // Initialize drag start values
      this.dragging = pdfNumber;
      this.startX = event.clientX;
      this.startY = event.clientY;

      const pdfScrollContainer = document.getElementById(
        `pdfColumn${pdfNumber}`
      ).parentElement;
      if (pdfScrollContainer) {
        // Store the initial scroll positions
        this.initialScrollLeft = pdfScrollContainer.scrollLeft;
        this.initialScrollTop = pdfScrollContainer.scrollTop;

        // Add mousemove and mouseup event listeners to handle dragging
        document.addEventListener("mousemove", this.onMouseMove);
        document.addEventListener("mouseup", this.onMouseUp);
      }
    },
    onMouseMove(event) {
      if (this.dragging !== null) {
        const pdfScrollContainer = document.getElementById(
          `pdfColumn${this.dragging}`
        ).parentElement;

        if (pdfScrollContainer) {
          // Calculate the delta of the mouse movement
          const deltaX = event.clientX - this.startX;
          const deltaY = event.clientY - this.startY;

          // Calculate new scroll positions
          let newScrollLeft = this.initialScrollLeft - deltaX;
          let newScrollTop = this.initialScrollTop - deltaY;

          // Apply boundaries to the scroll position
          newScrollLeft = Math.max(
            0,
            Math.min(
              newScrollLeft,
              pdfScrollContainer.scrollWidth - pdfScrollContainer.clientWidth
            )
          );
          newScrollTop = Math.max(
            0,
            Math.min(
              newScrollTop,
              pdfScrollContainer.scrollHeight - pdfScrollContainer.clientHeight
            )
          );

          // Set the new scroll position
          pdfScrollContainer.scrollLeft = newScrollLeft;
          pdfScrollContainer.scrollTop = newScrollTop;
        }
      }
    },
    onMouseUp() {
      if (this.dragging !== null) {
        // Remove event listeners when dragging stops
        document.removeEventListener("mousemove", this.onMouseMove);
        document.removeEventListener("mouseup", this.onMouseUp);

        this.dragging = null;
      }
    },

    generateID() {
      return Math.random().toString(36).substr(2, 9);
    },

    updateSidebarContent() {
      // Format the pagesDiff data into sidebarContent
      this.sidebarContent = this.pagesDiff.map((page, index) => ({
        pageNum: index + 1,
        added: page.added.map((item) => ({
          word: item[0],
          position: item[1],
          show: false,
          id: this.generateID(),
        })),
        removed: page.removed.map((item) => ({
          word: item[0],
          position: item[1],
          show: false,
          id: this.generateID(),
        })),
        changed: page.changed.map((item) => ({
          word: item[0],
          oldPosition: item[1],
          newWord: item[2],
          newPosition: item[3],
          show: false,
          id: this.generateID(),
        })),
        images: this.images[index].map((item) => ({
          image_diff1: item.image_diff1,
          image_diff2: item.image_diff2,
          position: item.position,
          show: false,
          id: this.generateID(),
        })),
      }));
      this.totalPages = this.sidebarContent.length;
      this.updateCurrentPageContent();
    },
    updateCurrentPageContent() {
      this.currentPageContent = this.sidebarContent[this.currentPage - 1];
      this.pageInput = this.currentPage;
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.updateCurrentPageContent();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.updateCurrentPageContent();
      }
    },
    goToPage() {
      const page = parseInt(this.pageInput);
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.updateCurrentPageContent();
      } else {
        // Reset to valid range if the input is out of bounds
        this.pageInput = this.currentPage;
      }
    },
  },
};
</script>

<style lang="scss" src="@/assets/css/PDFComparison.scss"></style>
