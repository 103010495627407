<template>
    <div class="flexUpload">
        <div class="flexUploadTitle">
            <img src="../assets/iconUpload.svg" alt="" />
            <h1>{{ $t("upload.compareUpload") }}</h1>
        </div>

        <div class="uploadDocument">
            <div class="uploadSection">
                <!-- PDF 1 Upload -->
                <div class="uploadSubsection">
                    <template v-if="pdf1">
                        <div class="fileInfo" :title="pdf1.name">
                            <p class="fileName">{{ pdf1.name }}</p>
                            <button class="removeFileButton" @click="removeFile(1)">✕</button>
                        </div>
                    </template>

                    <template v-else>
                        <button class="uploadButton" @click="selectFile(1)">
                            {{ $t("upload.fileChoseA") }}
                        </button>
                        <input
                            type="file"
                            ref="file1"
                            style="display: none"
                            @change="handleFileChange1"
                            :accept="allowedTypeFiles"
                        />
                        <div
                            class="dragDropBox"
                            ref="dragDropBox1"
                            @dragover="highlighting(1)"
                            @dragleave="dropLeave(1)"
                            @dragend="dropLeave(1)"
                            @dragover.prevent=""
                            @drop.prevent="handleDrop($event,1)"
                        >
                            <p class="text">{{ $t("upload.dragHereA") }}</p>
                        </div>
                    </template>
                </div>

                <!-- PDF 2 Upload -->
                <div class="uploadSubsection">
                    <template v-if="pdf2">
                        <div class="fileInfo" :title="pdf2.name">
                            <p class="fileName">{{ pdf2.name }}</p>
                            <button class="removeFileButton" @click="removeFile(2)">✕</button>
                        </div>
                    </template>
                <template v-else>
                    <button class="uploadButton" @click="selectFile(2)">
                        {{ $t("upload.fileChoseB") }}
                    </button>
                    <input
                        type="file"
                        ref="file2"
                        style="display: none"
                        @change="handleFileChange2"
                        :accept="allowedTypeFiles"
                    />
                    <div
                        class="dragDropBox"
                        ref="dragDropBox2"
                        @dragover="highlighting(2)"
                        @dragleave="dropLeave(2)"
                        @dragend="dropLeave(2)"
                        @dragover.prevent=""
                        @drop.prevent="handleDrop($event,2)"
                    >
                        <p class="text">{{ $t("upload.dragHereB") }}</p>
                    </div>
                </template>
                </div>
            </div>

            <div class="actionSection">
                <!-- Radio Button for "Force" -->
                <div class="forceToggle">
                    <input type="checkbox" id="forceCompare" v-model="forceComparison" />
                    <label for="forceCompare">{{ $t("upload.forceCompare") }}</label>
                </div>

                <!-- Compare Button -->
                <button class="compareButton" @click="emitFiles()">
                    {{ $t("upload.compareButton") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    emits: ["filesSelected"], 
    data() {
        return {
            pdf1: null,
            pdf2: null,
            allowedTypeFiles: ".pdf",
            forceComparison: false,
        };
    },
    methods: {
        selectFile(fileNumber) {
            if (this.$store.state.loggedUser) {
                let fileInputElement = fileNumber === 1 ? this.$refs.file1 : this.$refs.file2;
                fileInputElement.click();
            } else {
                this.$store.commit('pushMsg', {type: 'error', text: this.$t("StoreCommits.notLoggedIn")})
            }
        },
        handleFileChange1(event) {
            this.pdf1 = event.target.files[0];
            this.pdfCompareResult = null;
        },
        handleFileChange2(event) {
            this.pdf2 = event.target.files[0];
            this.pdfCompareResult = null;
        },
        removeFile(fileNumber) {
            if (fileNumber === 1) {
                this.pdf1 = null;
                // this.$refs.file1.value = null;
            } else if (fileNumber === 2) {
                this.pdf2 = null;
                // this.$refs.file2.value = null;
            }
            this.pdfCompareResult = null;
        },
        emitFiles() {
            if(this.pdf1 == null || this.pdf2 == null){
                console.error("One or both files not selected");
                this.$store.commit('pushMsg', {type: 'error', text: this.$t("upload.notSelected")})
                return
            }
            this.$emit("filesSelected", this.pdf1, this.pdf2, this.forceComparison);
        },
        highlighting(fileNumber) {
            setTimeout(() => {
                const dragDropBox = fileNumber === 1 ? this.$refs.dragDropBox1 : this.$refs.dragDropBox2;
                dragDropBox.classList.add('drop_area_highlight');
            }, 1);
        },
        dropLeave(fileNumber) {
            const dragDropBox = fileNumber === 1 ? this.$refs.dragDropBox1 : this.$refs.dragDropBox2;
            dragDropBox.classList.remove('drop_area_highlight');
        },
        handleDrop(event, fileNumber) {
            const files = event.dataTransfer.files;
            console.log(files)
            if (files.length > 0) {
                const file = files[0];
                if (fileNumber === 1) {
                    this.pdf1 = file;
                } else if (fileNumber === 2) {
                    this.pdf2 = file;
                }
            }
        },
    }
}
</script>


<style lang="scss" src="@/assets/css/FileUpload.scss"></style>